.neumorphic {
  /* box-shadow: 41px 41px 97px #1b1b1b, -41px -41px 97px #3b3b3b; */
}

.title {
  color: #2792ba;
  font-weight: bold;
  font-size: 1.2em;
}

.description {
  color: #2792ba;
  font-size: 1em;
  padding: 4px;
}
