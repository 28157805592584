.main {
  width: 100%;
  background-color: #000000 !important;
  height: 100vh;
  position: relative;
}

.xane_icon {
  position: absolute;
  top: 45vh;
  left: 40vw;
}

/* .camera {
  position: absolute;
  top: 25vh;
  left: 10vw;
  width: 96px;
  height: 96px;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 23px #121212, -5px -5px 23px #444444;
} */

.camera {
  position: absolute;
  top: 45vh;
  left: 40vw;
  width: 96px;
  height: 96px;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 0px #e4e4e486, -5px -5px 0px #ffffff7a;
  animation: displacementCamera 1s forwards;
}

@keyframes displacementCamera {
  to {
    transform: translate(-30vw, -20vh);
    box-shadow: 5px 5px 23px #e4e4e486, -5px -5px 23px #ffffff7a;
  }
}

/* .mic {
  position: absolute;
  top: 25vh;
  right: 10vw;
  width: 96px;
  height: 96px;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 23px #121212, -5px -5px 23px #444444;
} */

.mic {
  position: absolute;
  top: 45vh;
  left: 40vw;
  width: 96px;
  height: 96px;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 0px #e4e4e486, -5px -5px 0px #ffffff7a;
  animation: displacementMic 1s forwards;
}

@keyframes displacementMic {
  to {
    transform: translate(25vw, -20vh);
    box-shadow: 5px 5px 23px #e4e4e486, -5px -5px 23px #ffffff7a;
  }
}

/* .text {
  position: absolute;
  top: 65vh;
  left: 38vw;
  width: 96px;
  height: 96px;
  border-radius: 46px;a
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 23px #121212, -5px -5px 23px #444444;
} */

.text {
  position: absolute;
  top: 45vh;
  left: 40vw;
  width: 96px;
  height: 96px;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 0px #e4e4e486, -5px -5px 0px #ffffff7a;
  animation: displacementText 1.2s forwards;
}

@keyframes displacementText {
  to {
    transform: translateY(20vh);
    box-shadow: 5px 5px 23px #e4e4e486, -5px -5px 23px #ffffff7a;
  }
}

.talk-bubble {
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: lightyellow;
}

.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
  align-self: "flex-end";
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
/* .tri-right.border.btm-right:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -8px;
	bottom: -40px;
	border: 20px solid;
	border-color: #666 #666 transparent transparent;
} */
.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}
