.captureButton {
  /* margin-top: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #0066b2; */
  color:#103a2f !important;
  border-radius: 5px;
  bottom: 0px;
  left: calc(50% - 36px);
  z-index: 1000;
  position: absolute;
  margin: 0 auto;
}

.imageCanvas {
  margin-top: 20px;
  width: 100%;
  height: 200;
  display: flex;
  justify-content: center;
}

#webcam {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  z-index: -100;
  background-color: #103a2f ;
}
