.main {
  background-color: #103a2f !important;
  padding: 0;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.mic_voice {
  position: absolute;
  bottom: 60px;
  left: calc(50% - 36px);
  width: 5em;
  height: 5em;
  border-radius: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.transcript {
  font-family: "Courier Prime", monospace;
  color: #ffffff;
  direction: rtl;
  font-size: 1.4em;
  font-variant-caps: titling-caps;
  font-weight: bold;
  justify-self: flex-start;
}

.message {
  font-family: "Raleway", sans-serif;
  color: #2792ba;
  font-weight: 700;
  font-size: 1.4em;
}

.transcript::first-letter {
  text-transform: capitalize;
}
